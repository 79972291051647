.home-container {
	position: relative;
	padding: 50px;
}

.home-header {
	display: flex;
	column-gap: 20px;
	margin-bottom: 30px;
}

.home-header img {
	width: 250px;
	height: auto;
	border-right: 1px solid #000;
}

.nav-link {
	color: #fff;
}

.nav-link .MuiButtonBase-root:hover {
	background-color: #fff !important;
	color: #2e2e2e;
}

.toolbar-h2 {
	color: #fff;
}

.image-container {
	position: relative;
	overflow: hidden;
}

.image-container img {
	max-width: 900px;
}

.modal-image img {
	max-width: 100%;
}
